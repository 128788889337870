var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deck-comment-reference"},[_c('div',{staticClass:"line"},[_c('CardBulletedOutlineIcon',{staticClass:"title-icon",attrs:{"size":20}}),_vm._v(" "),_c('strong',[_c('a',{staticClass:"link",attrs:{"href":_vm.cardLink,"title":_vm.cardTooltip,"target":"_blank"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.card.title)+"\n\t\t\t")])]),_vm._v(" "),(_vm.dueDate)?_c('div',{staticClass:"spacer"}):_vm._e(),_vm._v(" "),(_vm.dueDate)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({ content: _vm.formattedDueDate }),expression:"{ content: formattedDueDate }",modifiers:{"top":true}}],staticClass:"due-date"},[_c('CalendarBlankIcon',{staticClass:"icon",attrs:{"size":20}}),_vm._v("\n\t\t\t"+_vm._s(_vm.dueDate)+"\n\t\t")],1):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"line"},[_c('DeckIcon',{staticClass:"title-icon",attrs:{"size":20}}),_vm._v(" "),_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({ content: _vm.stackTooltip }),expression:"{ content: stackTooltip }",modifiers:{"top":true}}],staticClass:"link",attrs:{"href":_vm.boardLink,"target":"_blank"}},[_vm._v("\n\t\t\t"+_vm._s(_vm.t('deck', '{stack} in {board}', { stack: _vm.stack.title, board: _vm.board.title }))+"\n\t\t")])],1),_vm._v(" "),_c('div',[(_vm.card.labels && _vm.card.labels.length)?_c('transition-group',{staticClass:"labels",attrs:{"name":"zoom","tag":"ul"},on:{"click":function($event){$event.stopPropagation();return _vm.openCard.apply(null, arguments)}}},_vm._l((_vm.labelsSorted),function(label){return _c('li',{key:label.id,style:(_vm.labelStyle(label))},[_c('span',[_vm._v(_vm._s(label.title))])])}),0):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"line description-assignees"},[(_vm.card.description)?_c('TextIcon',{staticClass:"icon",attrs:{"size":20}}):_vm._e(),_vm._v(" "),(_vm.card.description)?_c('div',{class:{
				'description': true,
				'short-description': _vm.shortDescription,
			}},[_c('NcRichText',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({ content: _vm.shortDescription ? _vm.t('deck', 'Click to expand description') : undefined }),expression:"{ content: shortDescription ? t('deck', 'Click to expand description') : undefined }",modifiers:{"top":true}}],attrs:{"text":_vm.card.description,"use-markdown":true},nativeOn:{"click":function($event){_vm.shortDescription = !_vm.shortDescription}}})],1):_vm._e(),_vm._v(" "),(_vm.card.assignedUsers .length > 0)?_c('div',{staticClass:"spacer"}):_vm._e(),_vm._v(" "),(_vm.card.assignedUsers .length > 0)?_c('AvatarList',{staticClass:"card-assignees",attrs:{"users":_vm.card.assignedUsers}}):_vm._e()],1),_vm._v(" "),(_vm.comment)?_c('div',{staticClass:"line comment-wrapper"},[_c('CommentProcessingOutlineIcon',{staticClass:"icon",attrs:{"size":20}}),_vm._v(" "),_c('div',{class:{
			'comment': true,
			'short-comment': _vm.shortComment,
		}},[_c('NcRichText',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:({ content: _vm.shortComment ? _vm.t('deck', 'Click to expand comment') : undefined }),expression:"{ content: shortComment ? t('deck', 'Click to expand comment') : undefined }",modifiers:{"top":true}}],attrs:{"text":_vm.commentMessageText,"use-markdown":false},nativeOn:{"click":function($event){_vm.shortComment = !_vm.shortComment}}})],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }